var setBgVideoSrc = function(){
  $('.bg-video').each(function(){
    var $video = $(this).find('video');
    if ($w.width() < app.breakpoints.md) {
      $video.html('');
    }else{
      if (!$video.find('source').length) {
        $video.append('<source src="' + $video.data('src') + '" type="video/mp4"></source>');
      }
    }
  });
};

setBgVideoSrc();
$w.on('resize', function(){
  setBgVideoSrc();
});