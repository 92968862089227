var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
if( isIE11 ){
  $b.addClass('legacy-browser');
  $('.bg-video').remove();
}

var menuDropdownClose = function(){
  $b.removeClass('dropdown-open');
  $('.menu-item')
    .removeClass('is-open')
    .find('.dropdown a').attr('tabindex','-1');
}
var searchClose = function(){
  $b.removeClass('search-open');
  $('#site-search-field').blur().val('');
  $('#site-search-close, #site-search-field, #site-search [type="search"]').attr('tabindex','-1');
};
var dropdownClose = function(){
  $('.dropdown').removeClass('is-open').find('.items a').attr('tabindex','-1');
 };

$b.on('keyup', function(e){
  // keyCode is getting deprecated use key instead when supported, which makes this messy
  evt = e || window.event;
  var isEscape = false;
  if ("key" in evt) {
    isEscape = (evt.key == "Escape" || evt.key == "Esc");
  } else {
    isEscape = (evt.keyCode == 27);
  }
  if (isEscape) {
    searchClose();
    dropdownClose();
    menuDropdownClose();
    $('.modal').removeClass('shown');
  }
 });

$('#menu-primary > .menu-item-has-children')
  .append(window.caret).children('.sub-menu')
  .wrap('<div class="dropdown"></div>')
  .wrap('<div class="container"></div>');

$('#menu-primary > .menu-item-has-children .dropdown').each(function(){
  $(this)
    .prepend('<div class="menu-title">' + $(this).closest('.menu-item').children('a').text() + '</div>')
    .prepend('<a href="#" class="back-bar" tabindex="-1"><span class="sr-only">back</span>' + window.arrow + '</a>');
  $(this).find('a').attr('tabindex','-1');
});

$('.back-bar').on('click', function(e){
  e.preventDefault();
  $(this).closest('.menu-item').removeClass('is-open');
});
  
$('.promo-data').each(function(){
  var $destination = $(this).closest('.menu-item').find('.dropdown .container');
  $destination.append('<div class="promo" style="background-image: url(' + $(this).data('image') + ')"/>');
  var $promo = $destination.find('.promo');
  if ($(this).data('micro') !== '') {
    $promo.append('<p class="micro-title">' + $(this).data('micro') + '</p>');
  }
  $promo.append('<p class="title">' + $(this).data('title') + '</p>');
  if ($(this).data('link') !== '') {
    $promo.append('<hr />');
    $promo.append('<a class="btn red-arrow" href="' + $(this).data('link') + '" tabindex="-1">' + $(this).data('link-text') + '</a>');
  }
});

$('#menu-primary > .menu-item-has-children > a, #utilities-toggle').on('click', function(e){
  e.preventDefault();
  e.stopPropagation();
  var wasOpen = $(this).closest('.menu-item').hasClass('is-open');
  menuDropdownClose(); // close everything
  if( wasOpen ) return; // was open, so we're done
  $b.addClass('dropdown-open');
  $(this).closest('.menu-item')
    .addClass('is-open')
    .find('.dropdown a').attr('tabindex','0');
});

$('body').on('click', function(e){
  menuDropdownClose();
});

if( $w.scrollTop() > 30 ){
  $('#site-header').addClass('is-scrolled');
}
$w.on('scroll', function(){
  if( $w.scrollTop() > 30 ){
    $('#site-header').addClass('is-scrolled');
  }else{
    $('#site-header').removeClass('is-scrolled');
  }
});

$('#menu-toggle').on('click', function(e){
  e.preventDefault();
  // menu toggle has dual functionality
  // it will open the menu, close the menu and close the search (on mobile only)
  if ($(this).hasClass('is-open')) {
    if ($b.hasClass('search-open')) {
      searchClose();
      if (!$b.hasClass('menu-open')) {
        $(this).removeClass('is-open');
      }
    } else {
      $b.removeClass('menu-open dropdown-open');
      $('.menu-item').removeClass('is-open');
      $(this).removeClass('is-open');
    }
  } else {
    $b.addClass('menu-open');
    $(this).addClass('is-open');
  }
});

// fake a hover state for our invisible button over the search form
$('#site-search-open').on('mouseenter focus', function(e){
  $('#site-search').addClass('in');
});
$('#site-search-open').on('mouseleave blur', function(e){
  $('#site-search').removeClass('in');
});
$('#site-search-open').on('click', function(e){
  e.preventDefault();
  $b.addClass('search-open');
  $('#menu-toggle').addClass('is-open');
  $('#site-search-field').focus();
  $('#site-search-close, #site-search-field, #site-search [type="search"]').attr('tabindex','0');
});
$('#site-search-close').on('click', function(e){
  e.preventDefault();
  searchClose();
});

$('.dropdown .trigger').on('click', function(e){
  e.preventDefault();
  e.stopPropagation();
  let $parent = $(this).closest('.dropdown');
  if ($parent.hasClass('is-open')) {
    // safe to close all drop downs
    dropdownClose();
  }else{
    // close other drop downs and open this one
    dropdownClose();
    $parent.addClass('is-open');
    $parent.find('.items a').attr('tabindex','0');
  }
});
$b.on('click', function(){
  dropdownClose();
});

// footer menus act like drop downs on mobile
$('.menu-group > .title').on('click', function(e){
  e.preventDefault();
  $(this).closest('.menu-group').toggleClass('is-open');
});

// expandable filters on mobile
$('#filters > .trigger').on('click', function(e){
  e.preventDefault();
  $('#filters').toggleClass('is-open');
});

$('#page-nav a').on('click', function(e){
  e.preventDefault();
  $('html, body').animate({
    scrollTop: $($(this).attr('href')).offset().top
  },500);
});

// ajax load log in/out menu item to avoid cache
$.ajax({
  url: window.ajaxEndpoint,
  method: 'GET',
  data: {
    action: 'dt_get_login_item'
  },
  success: function(data){
    $('.login-item').html(data);
  }
});