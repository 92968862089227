$b.on('click', '.block-accordion .toggle', function(e){
  e.preventDefault();
  let $parent = $(this).closest('.panel');
  $parent.toggleClass('is-open');
  if ($parent.hasClass('is-open')) {
    $parent.find('.content').slideDown();
  }else{
    $parent.find('.content').slideUp();
  }
});

// accordion only on mobile
$b.on('click', '.resource-category .content-toggle', function(e){
  e.preventDefault();
  $(this).toggleClass('is-open');
  $($(this).attr('href')).slideToggle();
});