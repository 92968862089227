import axios from 'axios';

window.Vue = require('vue');

var dtOrderStatusApp = new Vue({
    el: '#dt-order-status-app',
    data: {
        order: null,
        orders: [],
        $orderStatusForm: null,
        $orderHistoryForm: null,
        error: null,
        defaultError: "We're sorry, but we couldn't find your order.",
        loadingStatus: false,
        loadingHistory: false,
        filters: {
            timeframe: '',
            status: '',
        }
    },
    mounted() {
        this.$orderStatusForm = $(this.$el).find('form#dt-order-status-form');
        this.$orderHistoryForm = $(this.$el).find('form#dt-order-history-form');

        if (this.$orderStatusForm.data('auto-submit')) {
            this.findOrder();
        }

        if (this.$orderHistoryForm.data('auto-submit')) {
            this.findOrderHistory();
        }

    },
    methods: {
        findOrder() {
            this.order = null;
            this.orders = [];
            this.error = null;

            if (!this.validate()) {
                return;
            }

            this.loadingStatusStart();
            let url = this.$orderStatusForm.attr('action');
            let params = this.$orderStatusForm.serialize();
            axios
                .post(url, params)
                .then((response) => {
                    this.order = response.data.data.order;
                    this.error = response.data.data.error;
                    this.loadingStatusEnd();
                })
                .catch((error) => {
                    console.log(error);
                    this.loadingStatusEnd();
                });
        },
        findOrderHistory() {
            this.order = null;
            this.orders = [];
            this.error = null;
            this.loadingHistoryStart();
            let url = this.$orderHistoryForm.attr('action');

            // If we're initiating a new search, do a normal GET request
            if (!$(this.$orderHistoryForm).data('auto-submit')) {
                window.top.location.href = url + '?customer_account=' + encodeURIComponent(this.$orderHistoryForm.find('[name="customer_account"]').val());
                return;
            }

            // If we're auto-submitting, we need to do a POST request
            let params = this.$orderHistoryForm.serialize();
            url = this.$orderHistoryForm.data('action');
            $(this.$orderHistoryForm).data('auto-submit', false);   // Autosubmit once, then back to normal behavior
            axios
                .post(url, params)
                .then((response) => {
                    this.orders = response.data.data.orders;
                    this.error = response.data.data.error;
                    this.loadingHistoryEnd();
                })
                .catch((error) => {
                    console.log(error);
                    this.loadingHistoryEnd();
                });
        },
        validate() {
            let valid = true;
            let $orderNumber = this.$orderStatusForm.find('input[name=order_number]');
            let $zipCode = this.$orderStatusForm.find('input[name=zip_code]');
            $orderNumber.removeClass('error').attr('placeholder', 'Order Number');
            $zipCode.removeClass('error').attr('placeholder', 'Shipping Zip Code');
            if ($orderNumber.length && ($orderNumber.val() == '')) {
                $orderNumber.addClass('error').attr('placeholder', 'Please enter your order number');
                valid = false;
            }
            if ($zipCode.length && ($zipCode.val() == '')) {
                $zipCode.addClass('error').attr('placeholder', 'Please enter your shipping zip code');
                valid = false;
            }
            return valid;
        },
        formatAddress(address) {
            let formattedAddress = '';
            if (address.street) {
                formattedAddress += address.street;
                if (address.city || address.state || address.zip) {
                    formattedAddress += '<br>';
                }
            }
            if (address.city) {
                formattedAddress += address.city;
                if (address.state) {
                    formattedAddress += ', ' + address.state;
                }
            }
            if (address.zip) {
                if (address.city) {
                    formattedAddress += ' ';
                }
                formattedAddress += address.zip;
            }
            return formattedAddress;
        },
        loadingStatusStart() {
            this.loadingStatus = true;
            this.$orderStatusForm.css('opacity', '0.5');
        },
        loadingStatusEnd() {
            this.loadingStatus = false;
            this.$orderStatusForm.css('opacity', '1');
        },
        loadingHistoryStart() {
            this.loadingHistory = true;
            this.$orderHistoryForm.css('opacity', '0.5');
        },
        loadingHistoryEnd() {
            this.loadingHistory = false;
            this.$orderHistoryForm.css('opacity', '1');
        }
    },
    computed: {
        errorMessage() {
            return (this.error) ? this.error : this.defaultError;
        },
        filteredOrders() {
            return this.orders.filter((order) => {
                let matches = true;
                if (this.filters.timeframe) {
                    let startTimestamp = Date.now();
                    if (this.filters.timeframe == 'month') {
                        startTimestamp -= 30*24*60*60*1000;
                    } else if (this.filters.timeframe == '6-months') {
                        startTimestamp -= 180*24*60*60*1000;
                    } else if (this.filters.timeframe == 'year') {
                        startTimestamp -= 365*24*60*60*1000;
                    }
                    console.table({
                        'ordered at': order.ordered_at_timestamp*1000,
                        'start': startTimestamp,
                    });
                    matches = matches && (order.ordered_at_timestamp*1000) > startTimestamp;
                }
                if (this.filters.status) {
                    matches = matches && order.status.indexOf(this.filters.status) > -1;
                }
                return matches;
            });
        }
    }
});
