/**
 * App
 * ------
 * Specify vendor and app specific js files to be compiled
 */

// Vendor Requires
require('./vendor/imagesloaded.pkgd.min.js');
require('./vendor/slick.min');
require('./vendor/jquery.fitvids');

// App Requires
require('./app/config');
require('./app/helpers');
require('./app/global');
require('./app/accordion');
require('./app/bg-video');
require('./app/modal');
require('./app/product-media');
require('./app/slider');
require('./app/tabs');
require('./app/order-status');
